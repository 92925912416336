// router.js
import { createRouter, createWebHistory } from 'vue-router';
 
 
// 创建router实例
const router = createRouter({
  history: createWebHistory(),
  routes:[
    { path: '/',name:"home", component: ()=>{return import("@/view/homePage.vue")} },
    { path: '/about.html',name:"about", component: ()=>{return import("@/view/aboutPage.vue")} },
    { path: '/games.html',name:"games", component: ()=>{return import("@/view/gamesPage.vue")} },
    { path: '/job1.html',name:"job1", component: ()=>{return import("@/view/job1.vue")} },
    { path: '/job2.html',name:"job2", component: ()=>{return import("@/view/job2.vue")} },
    { path: '/job3.html',name:"job3", component: ()=>{return import("@/view/job3.vue")} },
    { path: '/job4.html',name:"job4", component: ()=>{return import("@/view/job4.vue")} },
    { path: '/career.html',name:"career", component: ()=>{return import("@/view/careerPage.vue")} },
    { path: '/term.html',name:"term", component: ()=>{return import("@/view/termsPage.vue")} },
    { path: '/privacy.html',name:"privacy", component: ()=>{return import("@/view/privacyPage.vue")} },
  ]
});
 
export default router;