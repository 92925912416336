import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"

// 引入element-plus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'



// 引入video播放器插件
import "video.js/dist/video-js.css";

// 引入字体文件
import "@/assets/fonts/text.css"

const app = createApp(App);

app.use(router);
app.use(ElementPlus);
app.mount("#app");

